import React, { useState } from 'react';
import { Button, Form } from 'antd';
import makeApiRequest from '../../api/makeApiRequest';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import './changePass.scss';
import { useForm } from '../../util/hooks/useForm';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { FormItem } from '../../components/FormItem/FormItem';
import { ApiError } from '../../util/functions/apiExceptionHandler';
import Prompt from '../../components/Prompt/prompt';

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ApiError | undefined>(
    undefined,
  );
  const userName = localStorage.getItem('email');
  const session = localStorage.getItem('session');
  const { form } = useForm();

  const handleSubmit = async () => {
    setLoading(true);
    const changePassword = await makeApiRequest(
      '/user/challenge-new-password',
      'POST',
      {},
      {
        email: userName,
        newPassword: newPassword,
        session: session,
      },
    );

    if (changePassword?.type === 'warning') {
      setLoading(false);
      setErrorMessage(changePassword);
      return;
    }

    if (
      changePassword.cognitoUsername &&
      changePassword.email &&
      changePassword.idToken &&
      changePassword.refreshToken
    ) {
      localStorage.setItem('user_address', changePassword.email);
      localStorage.setItem('id_token', changePassword.idToken);
      localStorage.setItem('refresh_token', changePassword.refreshToken);
      localStorage.setItem('cognito_username', changePassword.cognitoUsername);
      localStorage.setItem('is_loggedIn', 'true');
      localStorage.removeItem('challenge_name');
      localStorage.removeItem('message');
      localStorage.removeItem('is_first_login');
      window.location.replace('/');
    } else {
      setErrorMessage({
        type: 'warning',
        message: 'An error occurred. Please try again later.',
      });
    }
    setLoading(false);
  };
  const enableSubmit = () => {
    return (
      userName !== '' &&
      newPassword !== '' &&
      confirmNewPassword !== '' &&
      newPassword === confirmNewPassword
    );
  };

  return (
    <div className="main-body-form-container">
      <div className="main-body-form">
        <Form onFinish={handleSubmit} form={form}>
          <div className="change-password">
            <h2 style={{ textAlign: 'center' }}>Change Password</h2>
            <FormItem
              name="password"
              fieldName="Password"
              additionalRules={[
                {
                  validator: (form: RuleObject, value: StoreValue) => {
                    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w_]).{8,}$/;
                    if (regex.test(value)) return Promise.resolve();
                    return Promise.reject(
                      new Error(
                        'Password must be at least 8 characters with one digit, one special character, one capital letter, one small letter.',
                      ),
                    );
                  },
                },
              ]}
            >
              <PasswordInput
                style={{ marginBottom: '15px' }}
                label="New Password"
                onChange={(value) => setNewPassword(value.target.value)}
              />
            </FormItem>
            <FormItem
              name="confirmPassword"
              fieldName="Confirm Password"
              additionalRules={[
                {
                  validator: (form: RuleObject, value: StoreValue) => {
                    if (newPassword === confirmNewPassword)
                      return Promise.resolve();
                    return Promise.reject(
                      new Error(
                        'Confirm Password must match with the new password.',
                      ),
                    );
                  },
                },
              ]}
            >
              <PasswordInput
                style={{ marginBottom: '15px' }}
                label="Confirm New Password"
                onChange={(value) => setConfirmNewPassword(value.target.value)}
              />
            </FormItem>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                padding: 30,
              }}
            >
              <Button
                style={{
                  width: 500,
                  color: 'white',
                  backgroundColor: `${
                    enableSubmit() ? 'rgb(3, 128, 252)' : 'rgb(185 183 183)'
                  }`,
                  height: 45,
                  fontSize: 18,
                }}
                disabled={!enableSubmit()}
                loading={loading}
                onClick={form.submit}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Form>
      </div>
      {errorMessage && (
        <Prompt
          close={() => setErrorMessage(undefined)}
          promptTitle={'Something went wrong!'}
          message={errorMessage.message}
          type={errorMessage.type}
        ></Prompt>
      )}
    </div>
  );
};

export default ChangePassword;