// @flow
import TextBox from '../../components/TextBox/Textbox';
import { useMediaQuery } from 'react-responsive';
import './homepage.scss';
import { useEffect, useState } from 'react';
import { Select, Button } from 'antd';
import makeApiRequest from '../../api/makeApiRequest';
import { Image } from 'antd';
import createWallet from '../../assets/create-wallet.png';
import Prompt from '../../components/Prompt/prompt';

type RepUserFormProps = {
    firstName: string,
    lastName: string,
    npi: number | null,
    phoneNumber: string,
    appleWalletDesignId: number | null,
    googleWalletDesignId: number | null,
    repUserEmail: string,
};

interface WalletCard {
    id: number,
    title: string
}

const RepUserForm = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [appleCards, setAppleCards] = useState<WalletCard[]>([]);
    const [googleCards, setGoogleCards] = useState<WalletCard[]>([]);
    const userEmail = localStorage.getItem('user_address') || '';
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showError, setShowError] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalType, setModalType] = useState<'warning' | 'success' | 'info' | 'delete'>('success');
    const [formValues, setFormValues] = useState<RepUserFormProps>({
        firstName: '',
        lastName: '',
        npi: null,
        phoneNumber: '',
        appleWalletDesignId: null,
        googleWalletDesignId: null,
        repUserEmail: userEmail
    });

    const { Option } = Select;

    useEffect(() => {
        getGoogleAndAppleCards();
    }, []);

    const onTextBoxChange = (label: string | number, value: any) => {
        if(label === 'appleWalletDesignId' || label === 'googleWalletDesignId' || label === 'gender') {
            setFormValues(prevState => ({
                ...prevState,
                [label]: value
              }));
        } else if (label === 'npi') {
            setFormValues(prevState => ({
                ...prevState,
                [label]: parseInt(value.target.value.toString())
              }));
        } else {
        setFormValues(prevState => ({
            ...prevState,
            [label]: value.target.value
          }));
        }
    };

    const getGoogleAndAppleCards = async () => {
        const tempAppleCards: WalletCard[] = [];
        const tempGoogleCards: WalletCard[] = [];
        const appleWallet = await makeApiRequest(`/wallet/apple-list/owner/${userEmail}`,'GET');
        appleWallet && appleWallet.forEach((card: any, index: number) => {
           tempAppleCards.push({id: card.id, title: card.cardTitle});
        });
        const googleWallet = await makeApiRequest(`/wallet/google-list/owner/${userEmail}`,'GET');
        googleWallet && googleWallet.forEach((card: any, index: number) => {
            tempGoogleCards.push({id: card.id, title: card.cardTitle});
         });

         setAppleCards([...tempAppleCards]);
         setGoogleCards([...tempGoogleCards])
    };

    const checkValidation = () => {
        return formValues.firstName !== '' && formValues.lastName !== '' && formValues.npi && formValues.phoneNumber !== '' &&  formValues.repUserEmail !== '' && formValues.appleWalletDesignId !== null && formValues.googleWalletDesignId!== null;
    };

    const validateNPI = (s: string) => /^[12]\d{9}$/.test(s);

    const onSubmit = async () => {
        if(checkValidation() && formValues.npi && validateNPI(formValues.npi?.toString())) {
           setIsSaving(true);
            await makeApiRequest('/provider','POST', {}, {...formValues}).then(result => {
                setIsSaving(false);
                result.providerWalletDesignId && sendLink(result.providerWalletDesignId);
                setTimeout(() => setIsSaving(false), 1000);
            }).catch((error: any) => {
                setModalText(`Error: ${error._schema[0]}`)
                setModalType('warning');
                 setIsSaving(false);
                 setShowError(true);
            });
        } else {
            setShowError(true);
            setIsSaving(false);
        }
    };

    const sendLink = async (providerId: number) => {
        const sendLinkCall = await makeApiRequest('/wallet/send-link',
        'POST',
        {},
        {providerWalletDesignId: providerId, userEmail: userEmail});
        if(sendLinkCall.error) {
            setModalText(`Error: ${sendLinkCall.error}`)
            setModalType('warning');
        }
        else { setFormValues({
            firstName: '',
            lastName: '',
            npi: null,
            phoneNumber: '',
            appleWalletDesignId: null,
            googleWalletDesignId: null,
            repUserEmail: userEmail || ''
        });
        setShowError(false);
        setModalType('success');
        setModalText('Wallet card link sent to provider.');  }
    };

    return (
        <div className='main-body-form-container'>
         <div className='main-body-form'>
            {
                isMobile
                    ? <div style={{ padding: 30 }} >
                        <Image
                            preview={false}
                            width={'100%'}
                            src={createWallet}
                        />
                        <h2 style={{ textAlign: 'center' }} >Deliver Wallet Card</h2>
                         <TextBox
                            style={{ marginBottom: '15px' }}
                            defaultValue={formValues.firstName}
                            label='Provider First Name'
                            onChange={(value) => onTextBoxChange('firstName', value)}/>
                         <TextBox
                            style={{ marginBottom: '15px' }}
                            defaultValue={formValues.lastName}
                            label='Provider Last Name'
                            onChange={(value) => onTextBoxChange('lastName', value)}/>
                        <TextBox
                            style={{ marginBottom: '15px' }}
                            defaultValue={formValues.npi?.toString()}
                            placeholder='11111111111'
                            error={!(formValues.npi && validateNPI((formValues.npi)?.toString()))}
                            errorDescription='NPI must be 10 digit long and start with 1 or 2 and not contain any letters or special characters.'
                            label='Provider NPI'
                            maxLength={10}
                            onChange={(value) => onTextBoxChange('npi', value)}/>
                       <TextBox
                            style={{ marginBottom: '15px' }}
                            defaultValue={formValues.phoneNumber || '+1'}
                            error={showError && (formValues.phoneNumber.length < 10 || formValues.phoneNumber.length > 12)}
                            maskInput = {true}
                            mask={'+1 (999) 999-9999'}
                            placeholder='+12345678999'
                            maxLength={13}
                            label='Provider Phone Number'
                            onChange={(value) => onTextBoxChange('phoneNumber', value)}/>
                        <div className='d-flex'>
                                <div className='label' style={{ width: '45%' }}>Apple Wallet Card</div>
                                <Select
                                    style={{ width: '100%', marginTop: 20 }}
                                    defaultValue={formValues.appleWalletDesignId}
                                    placeholder="Select a person"
                                    optionFilterProp="children"
                                    onChange={(val) => onTextBoxChange('appleWalletDesignId', val)}
                                >
                                  {appleCards.map((card, index) => <Option value={card.id}>{card.title}</Option>)}
                                </Select>
                                <div className='label' style={{ width: '45%', marginTop: 10 }}>Google Wallet Card</div>
                                <Select
                                    style={{ width: '100%', marginTop: 20 }}
                                    placeholder="Select a person"
                                    defaultValue={formValues.googleWalletDesignId}
                                    optionFilterProp="children"
                                    onChange={(val) => onTextBoxChange('googleWalletDesignId', val)}
                                >
                                    {googleCards.map((card, index) => <Option value={card.id}>{card.title}</Option>)}
                                </Select>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', padding: 30 }}>
                                <Button
                                    style={{ width: 500, color: 'white', backgroundColor: 'rgb(3, 128, 252)', height: 45, fontSize: 18 }}
                                    className={`${!checkValidation() ? 'blue-button-disabled' : 'login-button'}`}
                                    disabled={!checkValidation()}
                                    loading={isSaving}
                                    onClick={() => onSubmit()}>
                                    {isSaving ? 'Saving...' : 'Create Wallet'}
                                </Button>
                            </div>
                    </div>
                    : <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                        <div style={{ width: '50%', margin: '55px' }}>
                        <h1 style={{ textAlign: 'center' }} >Deliver Wallet Card</h1>
                           <TextBox
                                style={{ marginBottom: '15px' }}
                                defaultValue={formValues.firstName}
                                label='Provider First Name'
                                onChange={(value) => onTextBoxChange('firstName', value)}/>
                           <TextBox
                                style={{ marginBottom: '15px' }}
                                defaultValue={formValues.lastName}
                                label='Provider Last Name'
                                onChange={(value) => onTextBoxChange('lastName', value)}/>
                            <TextBox
                                style={{ marginBottom: '15px' }}
                                defaultValue={formValues.phoneNumber || '+1'}
                                error={showError && formValues.phoneNumber.length !== 13}
                                maskInput = {true}
                                mask={'+1 (999) 999-9999'}
                                maxLength={13}
                                errorDescription='Invalid Phone Number'
                                label='Provider Phone Number'
                                onChange={(value) => onTextBoxChange('phoneNumber', value)}/>
                             <TextBox
                                style={{ marginBottom: '15px' }}
                                defaultValue={formValues.npi?.toString()}
                                placeholder='11111111111'
                                error={showError && !(formValues.npi && validateNPI((formValues.npi)?.toString()))}
                                errorDescription='NPI must be 10 digit long and start with 1 or 2 and not contain any letters or special characters.'
                                label='Provider NPI'
                                maxLength={10}
                                onChange={(value) => onTextBoxChange('npi', value)}/>
                            <div className='d-flex' style={{ marginTop: 20 }}>
                                <div className='label' style={{ width: '45%' }}>Apple Wallet Card</div>
                                <Select
                                    style={{ width: '100%', marginTop: 20 }}
                                    defaultValue={formValues.appleWalletDesignId}
                                    placeholder="Select Apple Wallet Card"
                                    optionFilterProp="children"
                                    onChange={(val) => onTextBoxChange('appleWalletDesignId', val)}
                                >
                                  {appleCards.map((card, index) => <Option value={card.id}>{card.title}</Option>)}
                                </Select>
                                <div className='label' style={{ width: '45%', marginTop: 20  }}>Google Wallet Card</div>
                                <Select
                                    style={{ width: '100%', marginTop: 20 }}
                                    defaultValue={formValues.googleWalletDesignId}
                                    placeholder="Select Google Wallet Card"
                                    optionFilterProp="children"
                                    onChange={(val) => onTextBoxChange('googleWalletDesignId', val)}
                                >
                                    {googleCards.map((card, index) => <Option value={card.id}>{card.title}</Option>)}
                                </Select>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', padding: 30 }}>
                                <Button
                                    style={{ width: 500, color: 'white', backgroundColor: `${ checkValidation() ? `rgb(3, 128, 252)` : '#ccc4c4'}`, height: 45, fontSize: 18 }}
                                    className={`${!checkValidation() ? 'blue-button-disabled' : 'login-button'}`}
                                    disabled={!checkValidation()}
                                    loading={isSaving}
                                    onClick={() => onSubmit()}>
                                    {isSaving ? 'Saving...' : 'Create Wallet'}
                                </Button>
                            </div>
                        </div>
                        <div style={{ width: '50%', margin: '55px' }}>
                        <Image
                            preview={false}
                            width={'100%'}
                            src={createWallet}
                        />
                        </div>
                    </div>
                }
                {modalText && <Prompt
                    promptTitle={modalText}
                    type={modalType}
                    close={() => {
                    modalType === 'success' ? window.location.reload() : setModalText(''); setFormValues({firstName: '',
                    lastName: '',
                    npi: null,
                    phoneNumber: '',
                    appleWalletDesignId: null,
                    googleWalletDesignId: null,
                    repUserEmail: userEmail}); }} />}
        </div>
        </div>
    );
};

export default RepUserForm;
