export type ApiError = {
  type: 'success' | 'warning';
  message: string;
};

export const apiExceptionHandler = (err: any): ApiError => {
  let message = '';
  if (err.error && Array.isArray(err.error)) {
    message = err.error.join(', ');
  } else {
    message = err.error;
  }

  if (err.message) message = err.message;

  if (!message) message = 'An error occurred. Please try again later.';

  return { type: 'warning', message: message };
};
