// @flow
import React from 'react';
import { Input } from 'antd';
import InputMask from 'react-input-mask';
import './text-box.scss';

const { TextArea } = Input;

type Props = {
    className?: string,
    multiLine?: boolean,
    maskInput?: boolean,
    rows?: number,
    defaultValue?: string,
    value?: string,
    label?: string,
    placeholder?: string,
    note?: React.ReactNode,
    onChange: (val?: any) => void,
    inputStyle?: React.CSSProperties,
    style?: React.CSSProperties,
    error?: boolean,
    mask? : string,
    errorDescription? : string,
    maxLength?: number,
    type?: string
}

export default function TextBox({
    className = '', style, multiLine = false, maskInput = false, rows, defaultValue, value, label, placeholder, note, onChange,
    inputStyle, error, mask, errorDescription, maxLength, type
}: Props){
    return (
        <div className={`text-box ${className}`} style={style}>
            {
                label !== undefined
                    ? <p className='text-box-label'>{label}</p>
                    : <></>
            }
            {
                multiLine
                    ? <TextArea
                        rows={rows}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        value={value || undefined}
                        onChange={onChange}/>
                    : maskInput
                    ? <InputMask
                        className={`input-mask ${error && 'input-mask-error'}`}
                        mask={mask || ''}
                        maskChar={null}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        value={value || undefined}
                        onInput={onChange}
                        style={{ width: '100%', ...inputStyle }}/> :
                         <Input
                        defaultValue={defaultValue}
                        type={type}
                        placeholder={placeholder}
                        value={value || undefined}
                        onInput={onChange}
                        maxLength={maxLength}
                        status={error ? 'error' : ''}
                        style={{ width: '100%', ...inputStyle }}/>
            }
            <span style={{ color: 'red', fontSize: '10px' }}>{error && (value !== '' ? `${(errorDescription !== '' ? errorDescription : `${label} is invalid.`)}` : `${label} is a required field.`)}</span>
            {note}
        </div>
    );
}
