import Button from '../Button/button';
import WalletIcon from '../../assets/wallet.png';
import './header.scss';

const Header = () => {
  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
  };

  const onLogout = () => {
    localStorage.removeItem('is_loggedIn');
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('cognito_username');
    localStorage.removeItem('user_address');
    localStorage.removeItem('email');
    localStorage.removeItem('session');
    localStorage.removeItem('challenge_name');
    localStorage.removeItem('message');
    localStorage.removeItem('is_first_login');
    window.location.replace('/');
  };

  return (
    <header style={headerStyle}>
      <div className="logo-container">
        <img src={WalletIcon} alt="Logo" className="logo" />
      </div>
      <Button
        size="lg"
        label="Logout"
        type="submit"
        className="mt-4 px-4 w-100 d-block logout-button"
        variant={'default'}
        handleClick={onLogout}
      />
    </header>
  );
};

export default Header;
