import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './Pages/LoginPage/login';
import HomePage from './Pages/HomePage/HomePage';
import ProtectedRoute from './Pages/ProtectedRoute';
import ChangePassword from './Pages/ChangePassword/changePassword';
import Header from './components/Header/header';

const App = () => {
  const isAuthenticated = () => {
    return localStorage.getItem('is_loggedIn') === 'true';
  };

  return (
    <HashRouter>
      <div>
        {isAuthenticated() && <Header />}
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated() ? (
                <Navigate to="/deliver-wallet-card" />
              ) : (
                <LoginPage />
              )
            }
          />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route
            path="/login"
            element={
              isAuthenticated() ? (
                <Navigate to="/deliver-wallet-card" />
              ) : (
                <LoginPage />
              )
            }
          />
          <Route
            path="/deliver-wallet-card"
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </HashRouter>
  );
};

export default App;
