import { createPortal } from 'react-dom';
import './prompt.scss';
import { ReactComponent as IconWarning } from './icon-warning.svg';
import { motion } from 'framer-motion';
import * as React from 'react';
import Button from '../Button/button';
import { CheckOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';

interface PromptProps {
    close: () => void;
    action?: () => void;
    actionName?: string;
    promptTitle?: string,
    type?: 'warning' | 'success' | 'info' | 'delete',
    message?: string | JSX.Element
}

const Prompt = ({ close, message, type, promptTitle, action, actionName }: PromptProps) => {
    return <React.Fragment>
        {
            createPortal(
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ type: 'spring', bounce: 0, duration: 0.40 }}
                    exit={{ opacity: 0 }}
                    className='prompt-wrapper'>
                    <motion.div
                        initial={{ opacity: 0, y: 0 }}
                        animate={{ opacity: 1, y: 100 }}
                        transition={{ type: 'spring', bounce: 0, duration: 0.40 }}
                        className='prompt-container'>
                        <div className='icon-container'>
                            { type === 'warning' && <IconWarning className='icon-warning'/>}
                            { type === 'success' && <CheckOutlined className='icon-success'/>}
                            { type === 'info' && <ExclamationCircleOutlined className='icon-info'/>}
                            { type === 'delete' && <DeleteOutlined className='icon-info'/>}
                        </div>

                        <div className='heading'>
                            {promptTitle}
                        </div>
                        <div className='note'>
                            {message}
                        </div>
                        <div className='d-flex'>
                            <Button
                                variant='outline'
                                style={{ width: 227 }}
                                label={`${type === 'delete' ? 'Cancel' : 'Close'}`}
                                handleClick={close} type={'button'}/>
                           { actionName && <Button
                                style={{ marginLeft: 20, width: 227 }}
                                variant='outline'
                                label={`${type === 'delete' ? 'Yes' : actionName}`}
                                handleClick={() => { action && action(); close(); } } type={'button'}/>}
                        </div>
                    </motion.div>
                </motion.div>
                , document.body)
        }
    </React.Fragment>;
};

export default Prompt;
