import { useMediaQuery } from 'react-responsive';
import './login.scss';
import Button from '../../components/Button/button';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import makeApiRequest from '../../api/makeApiRequest';
import loginImage from '../../assets/login.png';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import { Image, Form } from 'antd';
import { useForm } from '../../util/hooks/useForm';
import { FormItem } from '../../components/FormItem/FormItem';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { ApiError } from '../../util/functions/apiExceptionHandler';
import Prompt from '../../components/Prompt/prompt';

const RepWalletLogin = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ApiError | undefined>(
    undefined,
  );
  const { form } = useForm();
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (username !== '' && password !== '') {
      setLoading(true);
      localStorage.setItem('email', username);
      const loginInfo = await makeApiRequest(
        '/user/login',
        'POST',
        {},
        { email: username, password: password },
      );

      if (loginInfo?.type === 'warning') {
        setLoading(false);
        setErrorMessage(loginInfo);
      }

      if (loginInfo?.idToken) {
        localStorage.setItem('id_token', loginInfo.idToken);
        localStorage.setItem('user_address', loginInfo.email);
        localStorage.setItem('refresh_token', loginInfo.refreshToken);
        localStorage.setItem('cognito_username', loginInfo.cognitoUsername);
        localStorage.setItem(
          'is_loggedIn',
          loginInfo.idToken ? 'true' : 'false',
        );
        window.location.replace('/');
      } else if (localStorage.getItem('is_first_login') === 'true') {
        navigate('/change-password');
      }
      setLoading(false);
    }
  };

  const gradientStyle = {
    padding: '20px',
  };

  const leftHeading = {
    fontSize: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90%',
    width: '80%',
  };

  return (
    <div className="main-body-form-container" style={gradientStyle}>
      <div className="main-body-form" style={gradientStyle}>
        <Form onFinish={onSubmit} form={form}>
          {isMobile ? (
            <div style={{ padding: 30 }}>
              <h3>Login to Wallet Portal</h3>
              <div style={{ fontSize: 30, paddingBottom: 20 }}>
                <Image preview={false} width={'100%'} src={loginImage} />
              </div>
              <FormItem
                name="email"
                fieldName="Email"
                additionalRules={[
                  {
                    validator: (form: RuleObject, value: StoreValue) => {
                      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if (regex.test(value)) return Promise.resolve();
                      return Promise.reject(
                        new Error('Please enter a valid email address'),
                      );
                    },
                  },
                ]}
              >
                <input
                  className="form-control mt-4 input-style"
                  placeholder="Email"
                  type="email"
                  required={true}
                  onChange={(val) => setUsername(val.target.value)}
                  name="email"
                />
              </FormItem>
              <FormItem
                name="password"
                fieldName="Password"
                additionalRules={[
                  {
                    validator: (form: RuleObject, value: StoreValue) => {
                      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w_]).{8,}$/;
                      if (regex.test(value)) return Promise.resolve();
                      return Promise.reject(
                        new Error('Please enter a valid password'),
                      );
                    },
                  },
                ]}
              >
                <PasswordInput
                  style={{ marginBottom: '15px' }}
                  errorDescription="Password required."
                  error={password === ''}
                  label="Password"
                  onChange={(value) => setPassword(value.target.value)}
                />
              </FormItem>
              <Button
                label="Login"
                type="submit"
                disabled={username === '' || password === ''}
                className="mt-4 px-4 w-100 d-block login-button"
                variant={'default'}
                handleClick={onSubmit}
              />
            </div>
          ) : (
            <div className="form-wrapper">
              <div className="left" style={leftHeading}>
                <Image preview={false} width={'90%'} src={loginImage} />
              </div>
              <div className="right">
                <div style={{ padding: 30 }}>
                  <h2>Login to Wallet Portal</h2>
                  <FormItem
                    name="email"
                    fieldName="Email"
                    additionalRules={[
                      {
                        validator: (form: RuleObject, value: StoreValue) => {
                          const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          if (regex.test(value)) return Promise.resolve();
                          return Promise.reject(
                            new Error('Please enter a valid email address'),
                          );
                        },
                      },
                    ]}
                  >
                    <input
                      className="input-style"
                      placeholder="Email"
                      type="email"
                      required={true}
                      onChange={(val) => setUsername(val.target.value)}
                      name="email"
                    />
                  </FormItem>
                  <FormItem
                    name="password"
                    fieldName="Password"
                    additionalRules={[
                      {
                        validator: (form: RuleObject, value: StoreValue) => {
                          const regex =
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w_]).{8,}$/;
                          if (regex.test(value)) return Promise.resolve();
                          return Promise.reject(
                            new Error('Please enter a valid password'),
                          );
                        },
                      },
                    ]}
                  >
                    <PasswordInput
                      style={{ marginBottom: '15px' }}
                      errorDescription="Password required."
                      label="Password"
                      onChange={(value) => setPassword(value.target.value)}
                    />
                  </FormItem>
                  <Button
                    label="Login"
                    isLoading={loading}
                    disabled={username === '' || password === ''}
                    className="mt-4 px-4 w-100 d-block login-button"
                    variant={'default'}
                    handleClick={form.submit}
                  />
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
      {errorMessage && (
        <Prompt
          close={() => setErrorMessage(undefined)}
          promptTitle={'Something went wrong!'}
          message={errorMessage.message}
          type={errorMessage.type}
        ></Prompt>
      )}
    </div>
  );
};

export default RepWalletLogin;
