import clsx from 'clsx';
import { ReactComponent as IconLoader } from '../../assets/icon-loader.svg';
import './button.scss';

interface ButtonProps {
  label: String | JSX.Element;
  variant: 'default' | 'primary' | 'outline' | 'plain';
  type?: 'button' | 'submit';
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  isLoading?: boolean;
  style?: React.CSSProperties;
  handleClick: () => void;
  className?: string;
}

const Button = ({
    label = 'Button',
    variant = 'default',
    type = 'button',
    size = 'md',
    isLoading = false,
    disabled = false,
    style = {},
    handleClick,
    className
}: ButtonProps) => {
    return <div className='button-wrapper'>
        <button type={type} className={clsx(`size-${size} ${className}`, {
            'btn-plain': variant === 'plain',
            'btn-primary': variant === 'primary',
            'btn-outline-primary': variant === 'outline',
            'btn-disabled': disabled,
            'btn-loading': isLoading
        })}
        style={style}
        disabled={disabled}
        onClick={() => isLoading ? null : handleClick()}>
            {isLoading
                ? <IconLoader className='btn-icon-loader' style={{ height: '40px' }}/>
                : label}
        </button>
    </div>
    ;
};

export default Button;
