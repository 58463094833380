import axios from 'axios';
import { config, grantType } from '../config/constants';
import { apiExceptionHandler } from '../util/functions/apiExceptionHandler';

export const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': config.secretKey,
  },
});

/**
 * Serialize javascript object for sending to api
 * @param {Object} data
 * @returns {String}
 */
export function serialize(data: any) {
  return Object.keys(data)
    .map((keyName) => {
      return `${encodeURIComponent(keyName)}=${
        data[keyName] ? encodeURIComponent(data[keyName]) : ''
      }`;
    })
    .join('&');
}

/**
 * Method for making ajax calls to the site's api
 * @param {String} endpoint - the endpoint url
 * @param {String} method api methid POST | GET | DELETE
 * @param {Object|String} [params] - key:value pairs of the data to be sent to server
 * @param {Object|String} [data] - key:value pairs of the data to be sent to server
 * @param {String} contentType - header contentType of request
 * @returns {Promise}
 */
export default async function makeApiRequest(
  endpoint: string,
  method: string,
  params?: Object,
  data: any | string = null,
  contentType?: string,
) {
  const idToken = localStorage.getItem('id_token');

  const request: any = {
    method,
    url: endpoint,
    params: params,
    data: data ? (endpoint.includes('oauth') ? serialize(data) : data) : '',
  };

  const logout = () => {
    localStorage.removeItem('is_loggedIn');
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('cognito_username');
    localStorage.removeItem('user_address');
    localStorage.removeItem('email');
    localStorage.removeItem('session');
    localStorage.removeItem('challenge_name');
    localStorage.removeItem('message');
    localStorage.removeItem('is_first_login');
    window.location.replace('/');
  };

  if (endpoint.includes('oauth')) {
    request.headers = {
      'Content-Type': contentType || 'application/x-www-form-urlencoded',
      Authorization: `Basic ${
        data.grant_type === grantType.CLIENT_CREDENTIALS
          ? config.clientCredentialInternal
          : config.clientCredentialMobile
      }`,
    };
  } else {
    if (idToken) {
      request.headers = {
        Authorization: `Bearer ${idToken}`,
        'X-API-Key': config.secretKey,
      };
      request.headers['x-id-token'] = idToken;
    } else {
      request.headers = {
        'X-API-Key': config.secretKey,
      };
    }

    if (contentType) {
      request.headers['Content-Type'] = contentType;
    } else if (endpoint.includes('convert-to-pdf')) {
      request.headers['Content-Type'] = 'application/json';
      request.headers.Accept = 'application/pdf';
      request.responseType = 'blob';
    }
  }

  try {
    const response: any = await api(request);

    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 202
    ) {
      return response.data;
    } else {
      const error: any = new Error(response.statusText);

      error.response = response;
      throw error;
    }
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      return apiExceptionHandler(err.response.data);
    }
    if (
      (err.response && err.response.status === 403) ||
      (err.response.status === 401 &&
        err.response.data.error === 'Token is expired')
    ) {
      logout();
    }
    if (err.response && err.response.status === 401) {
      try {
        if (err?.response.data.challengeName) {
          localStorage.setItem(
            'challenge_name',
            err.response.data.challengeName,
          );
          localStorage.setItem('message', err.response.data.message);
          localStorage.setItem('session', err.response.data.session);
          localStorage.setItem('is_first_login', 'true');
        }
        return apiExceptionHandler(err.response.data);
      } catch (error: any) {
        throw err;
      }
    } else {
      return apiExceptionHandler(err.response.data);
    }
  }
}

/**
 * Method for making ajax calls to the site's api
 * @param {String} apiUrl - the api url
 * @param {String} endpoint - the endpoint url
 * @param {Object|string} [data] - key:value pairs of the data to be sent to server
 * @returns {Promise}
 */
export async function makeExternalRequest(apiUrl: string, endpoint: string, data = null) {
    const url = `${apiUrl}${endpoint}${data ? `?${serialize(data)}` : ''}`;
    const response = await fetch(url);

    if (response.ok) {
        const data = await response.json();

        return data;
    } else {
        const error: any = new Error(response.statusText);

        error.response = response;
        throw error;
    }
}
