// @flow
import React, { useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import './passwordInput.scss';

type Props = {
    className?: string,
    value?: string,
    label?: string,
    note?: React.ReactNode,
    onChange: (val?: any) => void,
    inputStyle?: React.CSSProperties,
    style?: React.CSSProperties,
    error?: boolean,
    errorDescription? : string,
    maxLength?: number,
}

export default function PasswordInput({
    className = '', style, value, label, note, onChange,
    inputStyle, error, errorDescription, maxLength
}: Props){
    const [iconVisible, setIconVisible] = useState(false);
    return (
        <div className={`text-box ${className}`} style={style}>
            {
                label !== undefined
                    ? <p className='text-box-label'>{label}</p>
                    : <></>
            } <div className='row-flex'>
                    <input className='form-control mt-4 input-style' placeholder='********' type={!iconVisible ? 'password' : 'text'}
                            required={true} onChange={onChange} name='password'/>
          {iconVisible ? <EyeInvisibleOutlined onClick={() => setIconVisible(!iconVisible)}/> : <EyeTwoTone onClick={() => setIconVisible(!iconVisible)}/>}
            </div>
            <span style={{ color: 'red', fontSize: '10px' }}>{error && (value !== '' ? `${(errorDescription !== '' ? errorDescription : `${label} is invalid.`)}` : `${label} is a required field.`)}</span>
            {note}
        </div>
    );
}
